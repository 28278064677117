import {Injectable} from '@angular/core';
import {Config} from "../../../../../setup/config";
import {BehaviorSubject, interval, map, mergeMap, Observable, timer} from "rxjs";
import {Box} from "../../models/box.model";
import '@mediapipe/face_detection';
import '@tensorflow/tfjs-core';
import '@tensorflow/tfjs-backend-webgl';
import * as faceDetection from '@tensorflow-models/face-detection';
import {FaceDetector, MediaPipeFaceDetectorMediaPipeModelConfig} from "@tensorflow-models/face-detection";
import * as _ from "lodash";
import {Face} from "../../models/face.model";

export enum StatusFaceDetector {
    NOT_LOADED = "not-loaded",
    LOADING = "loading",
    LOADED = "loaded"
}

@Injectable({
    providedIn: 'root'
})
export class FaceDetectorService {
    private _detector: FaceDetector;
    private _status: BehaviorSubject<StatusFaceDetector>;
    private _fps: number;

    get status(): Observable<StatusFaceDetector> {
        return this._status.asObservable();
    }

    constructor() {
        this.setup()
    }

    private setup(): void {
        this.setupVars()
    }

    private setupVars(): void {
        this._status = new BehaviorSubject<StatusFaceDetector>(StatusFaceDetector.NOT_LOADED)
        this._fps = Config.detection.face.fps
    }

    public async loadModels(): Promise<void> {
        if (this._status.value !== StatusFaceDetector.NOT_LOADED) {
            return
        }

        this._status.next(StatusFaceDetector.LOADING);

        const config: MediaPipeFaceDetectorMediaPipeModelConfig = {
            runtime: 'mediapipe',
            solutionPath: 'assets/scripts/face_detection',
        }
        const model = faceDetection.SupportedModels.MediaPipeFaceDetector;
        this._detector = await faceDetection.createDetector(model, config);

        this._status.next(StatusFaceDetector.LOADED);
    }

    public async detectFace(videoElement: HTMLVideoElement): Promise<any> {
        const config = {flipHorizontal: false};
        const faceList = await this._detector.estimateFaces(videoElement, config)

        if (faceList.length === 0) {
            return null
        }

        return _.first(faceList)
    }

    public startVideoFacialRecognition(
        videoElement: HTMLVideoElement,
    ): Observable<Face | null> {
        return timer(0, 1000 / this._fps)
            .pipe(
                mergeMap(() => this.detectFace(videoElement)),
                map((prediction: any) => {
                    if (prediction) {
                        const face = Face.from({
                            box: {
                                x: prediction.box.xMin,
                                y: prediction.box.yMin,
                                width: prediction.box.width,
                                height: prediction.box.height
                            }, keypoint: {
                                rightEye: prediction.keypoints.find((k: any) => k.name === "rightEye"),
                                leftEye: prediction.keypoints.find((k: any) => k.name === "leftEye"),
                                noseTip: prediction.keypoints.find((k: any) => k.name === "noseTip"),
                                mouthCenter: prediction.keypoints.find((k: any) => k.name === "mouthCenter"),
                                rightEarTragion: prediction.keypoints.find((k: any) => k.name === "rightEarTragion"),
                                leftEarTragion: prediction.keypoints.find((k: any) => k.name === "leftEarTragion"),
                            },
                            spin:{}
                        })

                        let dt = Math.abs(face.keypoint.rightEarTragion.x - face.keypoint.leftEarTragion.x)
                        let dp = Math.abs(face.keypoint.noseTip.x - face.keypoint.leftEarTragion.x)
                        face.spin.horizontal = dp / dt

                        const pty = (face.keypoint.leftEye.y + face.keypoint.rightEye.y)/2
                        const pby = face.keypoint.mouthCenter.y
                        const py = (face.keypoint.leftEarTragion.y + face.keypoint.rightEarTragion.y)/2
                        dt = Math.abs(pby - pty)
                        dp = Math.abs(pby - py)
                        face.spin.vertical = dp / dt

                        return face
                    } else {
                        return null
                    }
                }),
            );
    }
}
