import {Injectable} from '@angular/core';
import {map, Observable, of, switchMap} from 'rxjs';
import {Session} from '../models/session.model';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Config} from "../../../../setup/config";
import {StorageService} from "../../private/services/tools/storage.service";
import {KeyStorage} from "../enum/key-storage.enum";
import {LoadingController} from "@ionic/angular";
import {Strings} from "../classes/messages";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    constructor(
        private http: HttpClient,
        private storageSrv: StorageService,
        private loadingCtrl: LoadingController,
        private translateSrv: TranslateService
    ) {
    }

    public signInWithKey(key: string, device: string): Observable<Session> {
        const headers = new HttpHeaders({
            'Short-Key': key,
            'Origin-Device': device,
        });

        return this.http
            .post(`${Config.api.nfury}/api/v1/kyc/session`, {}, {headers})
            .pipe(
                map((response: any) => {
                    return Session.fromPlain({
                        id: response.sessionId,
                        token: response.accessToken,
                        document: response.document
                    });
                })
            );
    }

    public async factoryLoader(): Promise<void> {
        const loadingRef = await this.loadingCtrl.create({
            cssClass: "app-loading",
            showBackdrop: true,
            spinner: "circles",
            duration: 1000,
        });
        await loadingRef.present();
    }

    public companyMe(): Observable<any> {
        return of(this.storageSrv.get(KeyStorage.TOKEN_SESSION))
            .pipe(
                map(accessToken => {
                    if (accessToken) {
                        return accessToken;
                    }
                    throw new Error("accessToken not found");
                }),
                switchMap((accessToken) =>
                    this.http.get(`${Config.tyrona}/api/company/me`, {
                        headers: {
                            authorization: `Bearer ${accessToken}`,
                        },
                    }),
                ),
            );
    }
}


