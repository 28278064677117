import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {WebAppRoutingModule} from './web-app-routing.module';
import {WebAppComponent} from './components/web-app/web-app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthenticationInterceptorService} from "../private/interceptor/auth-interceptor.service";
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from "../../../environments/environment";
import {IonicModule} from "@ionic/angular";
import {AppService, initializerApp} from "./services/app.service";

@NgModule({
    declarations: [WebAppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        WebAppRoutingModule,
        HttpClientModule,
        IonicModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.serviceWorker.enabled,
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializerApp,
            multi: true,
            deps: [AppService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptorService,
            multi: true,
        },
    ],
    bootstrap: [WebAppComponent],
})
export class WebAppModule {
}
