import { Injectable } from '@angular/core';
import { Session } from '../../../webapp/models/session.model';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor() {}

    public set(key: string, value: any): void {
        localStorage.setItem(key, value);
    }

    public get(key: string): string | null {
        return localStorage.getItem(key);
    }

    public remove(key: string): void {
        localStorage.removeItem(key);
    }

    public clear(): void {
        localStorage.clear();
    }
}
