import {Expose, plainToInstance, Transform, Type} from "class-transformer";
import * as moment from "moment";
import {User} from "./user.model";
import {Company} from "./company.model";
import {DocumentSession} from "./session/document-session.model";

export class Session {
    @Expose()
    id: string;

    @Expose()
    token: string;

    @Expose()
    accessToken: string;

    @Expose()
    user: User;

    @Expose()
    company: Company;

    @Expose()
    @Type(() => DocumentSession)
    document: DocumentSession;

    @Expose()
    @Transform(({value}) => moment(value), {toClassOnly: true})
    expirationDate: moment.Moment;

    static from(payload: Record<string, any>): Session {
        return plainToInstance(Session, payload, {strategy: "excludeAll"});
    }

    static fromPlain(data: any): Session {
        return plainToInstance(Session, data);
    }

    public hasExpired(): boolean {
        if (!this.expirationDate) {
            return false;
        }

        const now = moment();
        return now.isAfter(this.expirationDate);
    }
}
