import { CanActivateFn } from '@angular/router';
import { map } from 'rxjs';
import { inject } from '@angular/core';
import { RouterService } from '../services/router.service';
import {SessionService} from "../services/session.service";


const publicPathUrl = ['/session'];

export const authGuard: CanActivateFn = (route, state) => {
    const path = state.url;
    const sessionSrv: SessionService = inject(SessionService);
    const routerSrv: RouterService = inject(RouterService);

    return sessionSrv.session.pipe(
        map((session) => {
            if (session) {
                if (isPublicPath(path)) {
                    routerSrv.goToOnboarding().then();
                    return false;
                } else {
                    return true;
                }
            } else {
                if (isPublicPath(path)) {
                    return true;
                } else {
                    routerSrv.goToPublic().then();
                    return false;
                }
            }
        })
    );
};

const isPublicPath = (path: string): boolean => {
    return !!publicPathUrl.find((p) => path.startsWith(p));
};
