import {Injectable} from "@angular/core";
import {ToastController} from "@ionic/angular";

export enum TypeNotification {
    empty = '',
    success = 'success',
    warning = 'warning',
    danger = 'danger'
}

export enum PositionNotification {
    top = 'top',
    bottom = 'bottom',
    middle = 'middle'
}

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    private _message: string;
    private _type: TypeNotification

    set message(value: string) {
        if (this._message !== value) {
            this._message = value;
            this.custom(this._message, 0, this._type).then()
        }
    }

    set type(value: TypeNotification) {
        if (this._type !== value) {
            this._type = value;
        }
    }

    constructor(
        private toastCtrl: ToastController
    ) {
        this.setup()
    }

    private setup(): void {
        this.setupVars();
    }

    private setupVars(): void {
        this._message = '';
        this._type = TypeNotification.empty;
    }

    public async custom(
        message: string,
        duration: number = 0,
        cssClass: TypeNotification = TypeNotification.empty,
        position: PositionNotification = PositionNotification.bottom
    ): Promise<void> {
        await this.dismissAll()

        const toast = await this.toastCtrl.create({
            message: message,
            duration: duration,
            position: position,
            cssClass: cssClass
        });

        await toast.present();
    }

    public success(
        message: string,
        duration: number = 0,
        position: PositionNotification = PositionNotification.bottom
    ): Promise<void> {
        return this.custom(message, duration, TypeNotification.success, position);
    }

    public warning(
        message: string,
        duration: number = 0,
        position: PositionNotification = PositionNotification.bottom
    ): Promise<void> {
        return this.custom(message, duration, TypeNotification.warning, position);
    }

    public danger(
        message: string,
        duration: number = 0,
        position: PositionNotification = PositionNotification.bottom
    ): Promise<void> {
        return this.custom(message, duration, TypeNotification.danger, position);
    }

    public async dismissTop(): Promise<void> {
        let toast = await this.toastCtrl.getTop();
        if (toast) {
            await toast.dismiss()
        }
    }

    public async dismissAll(): Promise<void> {
        let toast = await this.toastCtrl.getTop();
        while (toast) {
            await toast.dismiss()
            toast = await this.toastCtrl.getTop();
        }
    }
}
