import {Injectable} from '@angular/core';
import {Config} from '../../../../../setup/config';
import '@tensorflow/tfjs-backend-cpu';
import '@tensorflow/tfjs-backend-webgl';
import {BehaviorSubject, interval, map, mergeMap, Observable, timer} from 'rxjs';
import {Box} from '../../models/box.model';
import {createWorker, RecognizeResult, Worker} from 'tesseract.js';

export enum StatusTextDetector {
    NOT_LOADED = "not-loaded",
    LOADING = "loading",
    LOADED = "loaded"
}


@Injectable({
    providedIn: 'root',
})
export class TextDetectorService {
    private detector: Worker;
    private _status: BehaviorSubject<StatusTextDetector>;
    private _fps: number

    get status(): Observable<StatusTextDetector> {
        return this._status.asObservable();
    }

    constructor() {
        this.setup()
    }

    private setup(): void {
        this.setupVars()
    }

    private setupVars(): void {
        this._status = new BehaviorSubject<StatusTextDetector>(StatusTextDetector.NOT_LOADED)
        this._fps = Config.detection.text.fps;
    }

    public async loadModels(): Promise<void> {
        if (this._status.value !== StatusTextDetector.NOT_LOADED) {
            return
        }

        this._status.next(StatusTextDetector.LOADING);

        this.detector = await createWorker("eng")
        await this.detector.setParameters({
            tessedit_char_whitelist: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
        });

        this._status.next(StatusTextDetector.LOADED);
    }

    public async findText(videoElement: HTMLVideoElement, x: number, y: number, width: number, height: number): Promise<RecognizeResult> {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(videoElement, x, y, width, height, 0, 0, width, height);
        const image = canvas.toDataURL('image/png')

        return await this.detector.recognize(image)
    }

    public startVideoOcrRecognition(
        videoElement: HTMLVideoElement,
        x: number = 0,
        y: number = 0,
        width: number = videoElement.offsetWidth,
        height: number = videoElement.offsetHeight
    ): Observable<string> {
        return timer(0, 1000 / this._fps).pipe(
            mergeMap(() => this.findText(videoElement, x, y, width, height)),
            map((result: any) => {
                if (!result || !result.data || !result.data.text) {
                    return "";
                }

                const text = result.data.text;

                if (text.length === 0) {
                    return "";
                }

                const matches = this.findMatchesInText(text, 2);
                console.log(matches)
                return matches;
            })
        );
    }

    findMatchesInText(text: string, numberMatches: number = 1): string {
        const textLower = text.toLowerCase();
        const wordList = [
            'mexico',
            'colombia',
            'peru',
            'per',
            'instituto',
            'electoral',
            'nacional',
            'republica',
            'id',
            'mex',
            'idmex',
            'ine',
            'ife',
            'credencial',
            'col',
            'identificacion',
            'cedula',
            'jun',
            'jul',
            'ago',
            'sep',
            'oct',
            'nov',
            'dic',
            'ene',
            'feb',
            'mar',
            'abr',
            'may',
            "fecha",
            "nacimiento",
            "lugar",
            "estatura",
            "sexo",
            "expedicion",
            "ciudadania",
            "apellido",
            "nombre",
            "personal"
        ];

        const wordFoundList = wordList.filter((word) => textLower.includes(word));

        return wordFoundList.length >= numberMatches ? wordFoundList.join(', ') : '';
    }
}
