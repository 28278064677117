export const environment = {
    production: false,
    staging: false,
    apiDynamic: false,
    api: "https://dev.api.jaak.ai",
    apiOneToOne: "https://facade-1to1-http-635t26xtnq-uc.a.run.app",
    urlWeb: "https://dev.kyc.jaak.ai/",
    urlDashboard: "https://dev.jaak.ai/",
    tayrona: "https://dev.api.tayrona.jaak.ai",
    firebaseConfig: {
        apiKey: "AIzaSyCB6LFIE89STEczcCP9JGIMw-uHPkmzjT4",
        authDomain: "jaakrecog-1to1-similarity.firebaseapp.com",
        projectId: "jaakrecog-1to1-similarity",
        storageBucket: "jaakrecog-1to1-similarity.appspot.com",
        messagingSenderId: "19169445718",
        appId: "1:19169445718:web:ef05261dce9b978434b8f7",
        measurementId: "G-SH7GVCW22L",
    },
    blinkKey: "sRwCABFkZXYucmlnZWwuamFhay5haQZsZXlKRGNtVmhkR1ZrVDI0aU9qRTNNamd6TkRFeE56UTROelVzSWtOeVpXRjBaV1JHYjNJaU9pSXdNR1ptTURGbFpTMDJNekpqTFRSaU1HRXRaVGd5WmkwMU5UQTBZekV6TjJWaVpETWlmUT09+5fj5zmK5OLOEzSL6X7ujr89+8M+LVCtdBT2fWQA3How3mflOA3Mbeidz8y4YmZxYSwrGxuFDF6M/nq/lggwWYARmxV94nK1lQFwRDU2BBDyX/xeCWhkLTbBwWPowr9cXuiAGyO6Ujroz3KbaWRJ9YgvsQ==",
    environment: {
        production: false,
        staging: false,
        development: true,
    },
    serviceWorker: {
        enabled: false,
    },
    apis: {
        nfury: "https://dev.api.jaak.ai",
        rigel: "https://dev.api.rigel.jaak.ai",
        tayrona: "https://dev.api.tayrona.jaak.ai",
    },
    socket: {
        rigel: "wss://dev.api.rigel.jaak.ai",
    },
};
