import {Expose, plainToInstance, Type} from "class-transformer";

export class Point {
    @Expose()
    x: number;

    @Expose()
    y: number;
}

export class BoxFace {
    @Expose()
    x: number;

    @Expose()
    y: number;

    @Expose()
    width: number;

    @Expose()
    height: number;
}

export class SpinFace {
    @Expose()
    vertical: number;

    @Expose()
    horizontal: number;
}

export class KeypointFace {
    @Expose()
    @Type(() => Point)
    rightEye: Point

    @Expose()
    @Type(() => Point)
    leftEye: Point

    @Expose()
    @Type(() => Point)
    noseTip: Point

    @Expose()
    @Type(() => Point)
    mouthCenter: Point

    @Expose()
    @Type(() => Point)
    rightEarTragion: Point

    @Expose()
    @Type(() => Point)
    leftEarTragion: Point
}

export class Face {
    @Expose()
    @Type(() => BoxFace)
    box: BoxFace;

    @Expose()
    @Type(() => KeypointFace)
    keypoint: KeypointFace

    @Expose()
    @Type(() => SpinFace)
    spin: SpinFace

    static from(payload: Record<string, any>): Face {
        return plainToInstance(Face, payload, {strategy: 'excludeAll'});
    }
}
