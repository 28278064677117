import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class RouterService {
    constructor(private router: Router) {}

    public goToRoot(): void {
        window.location.href = '/';
    }

    public goToOnboarding(): Promise<boolean> {
        return this.router.navigate(['/onboarding']);
    }

    public goToPublic(): Promise<boolean> {
        return this.router.navigate(['/session']);
    }
}
