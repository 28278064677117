import {Injectable} from '@angular/core';
import '@tensorflow/tfjs-backend-cpu';
import '@tensorflow/tfjs-backend-webgl';
import * as tf from '@tensorflow/tfjs';
import {Tensor} from '@tensorflow/tfjs';
import * as _ from 'lodash';
import {BehaviorSubject, interval, map, mergeMap, Observable} from 'rxjs';
import {Box} from '../../models/box.model';
import {Config} from '../../../../../setup/config';

export enum StatusDocumentDetector {
    NOT_LOADED = 'not-loaded',
    LOADING = 'loading',
    LOADED = 'loaded',
}

export interface DocumentDetectionResult {
    coords: {
        x1: number;
        y1: number;
        x2: number;
        y2: number;
    };
    score: number;
}

@Injectable({
    providedIn: 'root',
})
export class DocumentDetectorService {
    private detector: any;
    private _status: BehaviorSubject<StatusDocumentDetector>;
    private _fps: number;
    private threshold = 0.7;

    get status(): Observable<StatusDocumentDetector> {
        return this._status.asObservable();
    }

    constructor() {
        this.setup();
    }

    private setup(): void {
        this.setupVars();
    }

    private setupVars(): void {
        this._status = new BehaviorSubject<StatusDocumentDetector>(
            StatusDocumentDetector.NOT_LOADED
        );
        this._fps = Config.detection.text.fps;
    }

    public async loadModels(): Promise<void> {
        if (this._status.value !== StatusDocumentDetector.NOT_LOADED) {
            return;
        }

        this._status.next(StatusDocumentDetector.LOADING);

        const path = '/assets/models/IDMTM1/model.json';
        this.detector = await tf.loadGraphModel(path);

        this._status.next(StatusDocumentDetector.LOADED);
    }

    public async findObjects(
        image: HTMLVideoElement
    ): Promise<DocumentDetectionResult | null> {
        const tensor = tf.browser.fromPixels(image)
        const frame = tensor.expandDims(0)
        const predictionList = await this.detector.executeAsync(frame) as Tensor[]

        return this.buildDetectionResult(predictionList, image);
    }

    public startVideoDocumentRecognition(
        videoElement: HTMLVideoElement
    ): Observable<Box | null> {
        return interval(1000 / this._fps).pipe(
            mergeMap(() => this.findObjects(videoElement)),
            map((prediction: DocumentDetectionResult | null) => {
                if (!prediction) {
                    return null;
                }

                return Box.fromPlain({
                    x: prediction.coords.x1,
                    y: prediction.coords.y1,
                    width: prediction.coords.x2 - prediction.coords.x1,
                    height: prediction.coords.y2 - prediction.coords.y1,
                });
            })
        );
    }

    private buildDetectionResult(
        predictionList: Array<any>,
        image: HTMLVideoElement
    ): DocumentDetectionResult | null {
        const scoreList = predictionList[2].dataSync()
        const boxList = predictionList[7].dataSync()

        const score = _.first(scoreList)
        if (!_.isNumber(score) || score < this.threshold) {
            return null
        }

        const boxes = _.slice(boxList, 0, 4) as Array<number>

        return {
            coords: {
                x1: boxes[1] * image.width,
                y1: boxes[0] * image.height,
                x2: boxes[3] * image.width,
                y2: boxes[2] * image.height,
            },
            score: score
        }
    }
}
