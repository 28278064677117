import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {Session} from '../models/session.model';
import {StorageService} from "../../private/services/tools/storage.service";
import {KeyStorage} from "../enum/key-storage.enum";

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    private _session: BehaviorSubject<Session | null>

    get session(): Observable<Session | null> {
        return this._session.asObservable();
    }

    set session(value: Session | null) {
        this._session.next(value);
    }

    constructor(
        private storageSrv: StorageService
    ) {
        this.setupVars();
    }

    private setupVars(): void {
        this._session = new BehaviorSubject<Session | null>(null);
    }

    public async loadSessionFromStorage(): Promise<void> {
        const idSession = this.storageSrv.get(KeyStorage.ID_SESSION);
        const tokenSession = this.storageSrv.get(KeyStorage.TOKEN_SESSION);

        if (idSession && tokenSession) {
            const session = Session.fromPlain({id: idSession, token: tokenSession});
            this._session.next(session)
        } else {
            this.storageSrv.clear();
            this._session.next(null);
        }
    }

    public clear(): void {
        this.storageSrv.remove(KeyStorage.ID_SESSION);
        this.storageSrv.remove(KeyStorage.TOKEN_SESSION)
        this._session.next(null);
    }
}
