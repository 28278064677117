import {SocketConfig} from "./socket-config";
import {CanvasConfig} from "./canvas-config";
import {ApiConfig} from "./api-config";
import {DetectionConfig} from "./detection-config";
import {VideoConfig} from "./video-config";
import {environment} from "../environments/environment";

export class Config {
    static api = ApiConfig
    static socket = SocketConfig;
    static canvas = CanvasConfig;
    static detection = DetectionConfig;
    static video = VideoConfig
    static tyrona = environment.apis.tayrona;
}
