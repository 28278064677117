import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'session',
    },
    {
        path: 'session',
        canActivate: [authGuard],
        loadChildren: () =>
            import('../public/public.module').then((m) => m.PublicModule),
    },
    {
        path: 'onboarding',
        canActivate: [authGuard],
        loadChildren: () =>
            import('../private/private.module').then((m) => m.PrivateModule),
    },
    {
        path: '**',
        redirectTo: 'session',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class WebAppRoutingModule {}
