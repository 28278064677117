import { Component, inject, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

declare var window: any;

@Component({
    selector: 'app-root',
    templateUrl: './web-app.component.html',
    styleUrls: ['./web-app.component.sass'],
})
export class WebAppComponent implements OnInit {
    private platform = inject(Platform);

    constructor() {
        this.platform.ready().then(() => {
            if (this.platform.is('android') || this.platform.is('ios')) {
                window.screen.orientation.lock('portrait');
            }
        });
    }

    ngOnInit(): void {
    }
}
