import {Injectable, Injector} from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent, HttpErrorResponse,
} from '@angular/common/http';
import {catchError, first, switchMap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {SessionService} from "../../webapp/services/session.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root',
})
export class AuthenticationInterceptorService implements HttpInterceptor {
    constructor(
        private sessionSrv: SessionService,
        private injector: Injector,
    ) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return this.sessionSrv.session.pipe(
            first(),
            switchMap((session) => {
                let request = req;

                if (session) {
                    const translate = this.injector.get(TranslateService);
                    request = req.clone({
                        setHeaders: {
                            authorization: `Bearer ${session?.token}`,
                            language: translate.currentLang || "es",
                        },
                    });
                }

                return next.handle(request)
                    .pipe(
                        catchError((error: HttpErrorResponse) => {
                            if (error.status === 403) {
                                this.sessionSrv.clear();
                            }

                            return throwError(error);
                        })
                    )
            })
        );
    }
}
