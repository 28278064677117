export class RestrictVideoConfig {
    static minArea: number = 0.2;
    static maxArea: number = 0.4;
    static leftRotationFace: number = 0.3;
    static rightRotationFace: number = 0.7;
    static topRotationFace: number = 0.6;
    static bottomRotationFace: number = 1;
}

export class VideoConfig {
    static restrict = RestrictVideoConfig;
    static minimumPixel = 129600;
}
