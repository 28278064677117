import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { RouterService } from './router.service';
import { FaceDetectorService } from '../../private/services/detectors/face-detector.service';
import { TextDetectorService } from '../../private/services/detectors/text-detector.service';
import { Platform } from '@ionic/angular';
import * as _ from 'lodash';
import { DocumentDetectorService } from '../../private/services/detectors/document-detector.service';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    private _platformList: Array<string>;

    get platformList(): Array<string> {
        return this._platformList;
    }

    get isMobile(): boolean {
        return _.some(['mobile'], (value: string) =>
            _.includes(this._platformList, value)
        );
    }

    constructor(
        private sessionSrv: SessionService,
        private routerSrv: RouterService,
        private platform: Platform,
        private faceDetectorSrv: FaceDetectorService,
        private textDetectorSrv: TextDetectorService,
        private documentDetectorService: DocumentDetectorService
    ) {
        this.setup();
    }

    private setup(): void {
        this.setupVars();
    }

    private setupVars(): void {
        this._platformList = this.platform.platforms();
    }

    public async initializerApp(): Promise<void> {
        this.faceDetectorSrv.loadModels().then();
        this.textDetectorSrv.loadModels().then();
        this.documentDetectorService.loadModels().then();
    }

    public async signOut(reload: boolean = true): Promise<void> {
        this.sessionSrv.clear();

        if (reload) {
            this.routerSrv.goToRoot();
        }
    }
}

export function initializerApp(appSrv: AppService) {
    return async () => {
        await appSrv.initializerApp();
        await appSrv.signOut(false);
    };
}
