import {Injectable} from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent, HttpErrorResponse,
} from '@angular/common/http';
import {catchError, first, switchMap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {SessionService} from "../../webapp/services/session.service";
import {RouterService} from "../../webapp/services/router.service";
import {AppService} from "../../webapp/services/app.service";
import {NotificationService} from "../services/tools/notification.service";

@Injectable({
    providedIn: 'root',
})
export class AuthenticationInterceptorService implements HttpInterceptor {
    constructor(
        private sessionSrv: SessionService,
        private appSrv: AppService,
        private routerSrv: RouterService,
        private toastSrv: NotificationService,
    ) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return this.sessionSrv.session.pipe(
            first(),
            switchMap((session) => {
                let request = req;

                if (session) {
                    request = req.clone({
                        setHeaders: {
                            authorization: `Bearer ${session?.token}`,
                        },
                    });
                }

                return next.handle(request)
                    .pipe(
                        catchError( (error: HttpErrorResponse) => {
                            if (error.status === 403) {
                                this.appSrv.signOut().then();
                                this.toastSrv.danger('Session expirada, por favor inicia sesión nuevamente', 3000).then()
                            }

                            return throwError(error);
                        })
                    )
            })
        );
    }
}
