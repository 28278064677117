export const environment = {
    environment: {
        production: false,
        staging: false,
        development: true,
    },
    serviceWorker: {
        enabled: false,
    },
    apis: {
        nfury: "https://dev.api.jaak.ai",
        rigel: "https://dev.api.rigel.jaak.ai",
    },
    socket: {
        rigel: "wss://dev.api.rigel.jaak.ai"
    }
};
