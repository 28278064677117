import {Expose, plainToInstance} from 'class-transformer';

export class Session {
    @Expose()
    id: string;

    @Expose()
    token: string;

    static fromPlain(data: any): Session {
        return plainToInstance(Session, data);
    }
}
