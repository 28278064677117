import { Expose, plainToInstance, Type } from "class-transformer";
import { Country } from "./country/country.model";

export class ColorsTheme {
    @Expose()
    primary: string;

    @Expose()
    secondary: string;
}

export class PictureTheme {
    @Expose()
    logo: string;
}

export class Theme {
    @Expose()
    @Type(() => ColorsTheme)
    colors: ColorsTheme;

    @Expose()
    @Type(() => PictureTheme)
    pictures: PictureTheme;

    @Expose()
    @Type(() => Array<Country>)
    countryList: Array<Country>;

    static from(payload: Record<string, any>): Theme {
        return plainToInstance(Theme, payload);
    }
}
