import {Injectable} from '@angular/core';
import {Config} from "../../../../../setup/config";
import {BehaviorSubject, interval, map, mergeMap, Observable, timer} from "rxjs";
import {Box} from "../../models/box.model";
import '@mediapipe/face_detection';
import '@tensorflow/tfjs-core';
import '@tensorflow/tfjs-backend-webgl';
import * as faceDetection from '@tensorflow-models/face-detection';
import {FaceDetector, MediaPipeFaceDetectorMediaPipeModelConfig} from "@tensorflow-models/face-detection";
import * as _ from "lodash";

export enum StatusFaceDetector {
    NOT_LOADED = "not-loaded",
    LOADING = "loading",
    LOADED = "loaded"
}

@Injectable({
    providedIn: 'root'
})
export class FaceDetectorService {
    private _detector: FaceDetector;
    private _status: BehaviorSubject<StatusFaceDetector>;
    private _fps: number;

    get status(): Observable<StatusFaceDetector> {
        return this._status.asObservable();
    }

    constructor() {
        this.setup()
    }

    private setup(): void {
        this.setupVars()
    }

    private setupVars(): void {
        this._status = new BehaviorSubject<StatusFaceDetector>(StatusFaceDetector.NOT_LOADED)
        this._fps = Config.detection.face.fps
    }

    public async loadModels(): Promise<void> {
        if (this._status.value !== StatusFaceDetector.NOT_LOADED) {
            return
        }

        this._status.next(StatusFaceDetector.LOADING);

        const config: MediaPipeFaceDetectorMediaPipeModelConfig = {
            runtime: 'mediapipe',
            solutionPath: 'assets/scripts/face_detection',
        }
        const model = faceDetection.SupportedModels.MediaPipeFaceDetector;
        this._detector = await faceDetection.createDetector(model, config);

        this._status.next(StatusFaceDetector.LOADED);
    }

    public async detectFace(videoElement: HTMLVideoElement): Promise<any> {
        const config = {flipHorizontal: false};
        const faceList = await this._detector.estimateFaces(videoElement, config)

        if (faceList.length === 0) {
            return null
        }

        return _.first(faceList)
    }

    public startVideoFacialRecognition(
        videoElement: HTMLVideoElement,
    ): Observable<Box | null> {
        return timer(0, 1000 / this._fps)
            .pipe(
                mergeMap(() => this.detectFace(videoElement)),
                map((face: any) => {
                    if (face) {
                        return Box.fromPlain({
                            x: face.box.xMin,
                            y: face.box.yMin - (face.box.height * 0.15),
                            width: face.box.width,
                            height: face.box.height * 1.05
                        })
                    } else {
                        return null
                    }
                }),
            )
    }
}
